<template>
  <div class="flight-summary-chart" ref="chart-body">

  </div>
</template>

<script>
import c3 from 'c3';
import d3 from 'd3';
import _ from 'lodash';

export default {
  name: 'FlightSummaryChart',
  data() {
    return {
      chartObject: null,
      maunallyAxisY: null,
      months: [this.$t('dashboard.Jan'), this.$t('dashboard.Feb'), this.$t('dashboard.Mar'), this.$t('dashboard.Apr'),this.$t('dashboard.May'), this.$t('dashboard.Jun'), this.$t('dashboard.Jul'), this.$t('dashboard.Aug'), this.$t('dashboard.Sep'), this.$t('dashboard.Oct'), this.$t('dashboard.Nov'), this.$t('dashboard.Dec')],
      loadingAnimation: null,
    };
  },
  computed: {
    data() {
      if (this.summaries.length === 0) {
        return ;
      }
      let xColumn = ['x'];
      xColumn = xColumn.concat(this.summaries.map((sum) => {
        return new Date(sum.month);
      }));

      let flightColumn = [this.$t('dashboard.total_flight')];
      flightColumn = flightColumn.concat(this.summaries.map((sum) => {
        return sum.count;
      }));

      let flightTime = [this.$t('dashboard.total_flight_time')];
      flightTime = flightTime.concat(this.summaries.map((sum) => {
        return sum.duration / 60;
      }));

      let columns = [
        xColumn,
        flightTime,
        flightColumn,
      ];
      return {
        x: 'x',
        columns,
        axes: {
          'Total Flights': 'y2',
          'Total Flights Time': 'y',
        },
        type: this.dataType,
        colors: this.dataColor,
        tooltip: this.tooltip,
      };
    },
    axisAdjust() {
      let flightColumn = this.summaries.map((sum) => {
        return sum.count;
      });
      let flightMax = Math.max(...flightColumn);

      let customFlightAttribute = {};
      if ( flightMax <= 100) {
        flightMax = this.axisFlightsTickValues.find(r => r > flightMax);
        customFlightAttribute = {
          max: flightMax,
          "tick": {
            values: this.axisFlightsTickValues,
          },
        };
        this.maunallyAxisY = true;
      } else {
        this.maunallyAxisY = false;
      }

      return {
        x: {
          ...this.axis.x,
        },
        y: {
          ...this.axis.y,
        },
        y2: {
          ...this.axis.y2,
          ...customFlightAttribute
        },
      };

    },
    axisFlightsTickValues() {
      // let column = this.summaries.map((sum) => {
      //   return sum.count;
      // });

      let initValue = 0;
      let range = 10;

      // let max = Math.max(...column);
      let max = 110;
      let ranges = _.range(initValue, max, range);
      let lastRange = ranges[ranges.length - 1];
      if (lastRange < max) {
        ranges.push(lastRange + range);
      }

      return ranges;
    },
  },
  watch: {
    data(now, old) {
      // 載入提示結束
      this.loadingAnimation.close();

      // 更新圖表
      this.updateChart();
    },
    maunallyAxisY(now, old) {
      if (old !== null && old !== now) {
        console.log('reset chart');
        this.resetChart();
      }
    },
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    loading() {
      this.loadingAnimation = this.$loading({
        target: this.$refs['chart-body'],
      });
    },
    resetChart() {
      if (this.chartObject != null) {
        this.chartObject.destroy();
        this.chartObject = null;
      }
      this.updateChart();
    },
    updateChart() {
      if (this.chartObject == null) {
        this.genernateChart();
        return;
      }

      // console.log('update chart');
      this.chartObject.load({
        columns: this.data.columns,
      });

      // update y axis max value
      this.chartObject.axis.max({
        y2: this.axisAdjust.y2.max,
      });

      // d3.select(".c3-axis-y-label").attr("transform", "translate(25,30)")
    },
    genernateChart() {
      if (this.data == null) {
        return ;
      }

      // console.log('draw chart');
      this.chartObject = c3.generate({
        bindto: this.$refs['chart-body'],
        data: this.data,
        axis: this.axisAdjust,
        tooltip: this.tooltip,
      });

      // for debug
      window.chartObject = this.chartObject;
    },
  },
  props: {
    // data: {
    //   required: true,
    //   type: Object,
    // },
    dataType: {
      required: false,
      default: 'bar',
    },
    dataColor: {
      required: false,
      default() {
        return {
          'Total Flights Time': '#4f9fdd',
          'Total Flights': '#d8d8d8',
        };
      },
    },
    summaries: {
      required: true,
      type: Array,
    },
    axis: {
      required: false,
      type: Object,
      default() {
        let _this = this;
        return {
          x: {
            type: 'timeseries',
            tick: {
              // format: '%m'
              format(time) {
                var month = time.getMonth();
                return _this.months[month];
              },
            },
          },
          y: {
            show: true,
            label: {
              text: '('+this.$t('dashboard.Mins')+')',
              position: 'outer-top',
            },
            min: 0,
            padding: {
              bottom: 0,
              top: 0,
            },
          },
          y2: {
            show: true,
            label: {
              text: '('+this.$t('dashboard.total_flight')+')',
              // ref: https://c3js.org/samples/axes_label_position.html
              position: 'outer-top'
            },
            min: 0,
            padding: {
              bottom: 0,
              top: 0,
            },
          },
        };
      },
    },
    tooltip: {
      required: false,
      default() {
        return {
          show: false
        };
      },
    },
  },
}
</script>

<style lang="scss">
.flight-summary-chart {
  height: 100%; 
}
</style>
