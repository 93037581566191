import { appAxios as axios } from '@/services/api/';

const apiUrl = '/v1/groups';

export default {
  getAll(group) {
    return axios.get(`${apiUrl}/${group}/weather_stations`).then((response) => response.data);
  },
  get(group, id) {
    return axios.get(`${apiUrl}/${group}/weather_stations/${id}`).then((response) => response.data);
  },
  create(group, data) {
    return axios.post(`${apiUrl}/${group}/weather_stations`, data).then((response) => response.data);
  },
  update(group, id, data) {
    return axios.put(`${apiUrl}/${group}/weather_stations/${id}`, data).then((response) => response.data);
  },
  delete(group, id) {
    return axios.delete(`${apiUrl}/${group}/weather_stations/${id}`).then((response) => response.data);
  },
  status(group, id) {
    return axios.get(`${apiUrl}/${group}/weather_stations/${id}/status`).then((response) => response.data);
  },
  simulate(group, id, command) {
    // return axios.post(`${apiUrl}/${group}/weather_stations/${id}/simulate`, command ).then((response) => {
    //   return response.data;
    // });
  },
};
