export const directions = [
  {
    label: 'N',
    down: 0,
    up: 11.25,
  },
  {
    label: 'NNE',
    down: 11.25,
    up: 33.75,
  },
  {
    label: 'NE',
    down: 33.75,
    up: 56.25,
  },
  {
    label: 'NEE',
    down: 56.25,
    up: 78.75,
  },
  {
    label: 'E',
    down: 78.75,
    up: 101.25,
  },
  {
    label: 'SEE',
    down: 101.25,
    up: 123.75,
  },
  {
    label: 'SE',
    down: 123.75,
    up: 146.25,
  },
  {
    label: 'SSE',
    down: 146.25,
    up: 168.75,
  },
  {
    label: 'S',
    down: 168.75,
    up: 191.25,
  },
  {
    label: 'SSW',
    down: 191.25,
    up: 213.75,
  },
  {
    label: 'SW',
    down: 213.75,
    up: 236.25,
  },
  {
    label: 'SWW',
    down: 236.25,
    up: 258.75,
  },
  {
    label: 'W',
    down: 258.75,
    up: 281.25,
  },
  {
    label: 'NWW',
    down: 281.25,
    up: 303.75,
  },
  {
    label: 'NW',
    down: 303.75,
    up: 326.25,
  },
  {
    label: 'NNW',
    down: 326.25,
    up: 348.75,
  },
  {
    label: 'N',
    down: 348.75,
    up: 360,
  },
];

export const WindDirection = (value) => {
  const direction = directions.find((d) => d.down < value && d.up >= value);
  return direction ? direction.label : directions[0].label;
};
