import { appAxios as axios } from '@/services/api/';

const apiUrl = '/v1/groups';

export default {
  histroySummery(groupId, droneId) {
    return axios
      .get(`${apiUrl}/${groupId}/drones/${droneId}/history_summary`)
      .then((response) => response.data);
  },
  reUploadImage(groupId, droneId, historyId) {
    return axios
      .post(`${apiUrl}/${groupId}/drones/${droneId}/histories/${historyId}/reUploadImage`)
      .then((response) => response.data);
  },
  getHistoryFile(groupId, droneId, historyId) {
    return axios
      .get(`${apiUrl}/${groupId}/drones/${droneId}/histories/${historyId}/material`)
      .then((response) => response.data);
  },
  deleteHistoryFile(groupId, droneId, historyId, data) {
    return axios
      .delete(`${apiUrl}/${groupId}/drones/${droneId}/histories/${historyId}/material`, { data })
      .then((response) => response.data);
  },
};
