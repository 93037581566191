

<script> 
  export default {
    name: 'with-root',   
    props: {     
      show: {
        type: Boolean,
        default: true,
      },     
    },
    functional: true,
    render(h, ctx) {
      const children = ctx.children.filter(vnode => vnode.tag) // remove unnecessary text nodes
      // console.log(children)
      if (children.length !== 1) {
        console.warn('this component accepts only one root node in its slot')
      }
      if (ctx.props.show) {
        return children[0]
      } else {
        return children[0].children
      }
    },
  };
</script>

<style scoped>

</style>
