<template>
  <div class="dashboard">
    <div class="weather-container">
      <div class="weather-header">
        <span v-if="stations.length > 0">{{ $t("dashboard.weatherStation") }} ({{ stationPage + 1 }}/{{ stations.length }})
        </span>
        <span v-else>{{ $t("dashboard.weatherStation") }} (0)</span>
      </div>
      <div class="weather-select-container">
        <div
          v-if="stations.length <= 0"
          class="no-device"
        >
          <div class="no-mission__content">
            {{ $t("dashboard.no_devices") }}
          </div>
        </div>
        <template v-else>
          <button
            :disabled="stationPage <= 0"
            class="weather-select__navi image-button pervious"
            @click="stationPage -= 1"
          />
          <div class="weather-select-anchor-pane">
            <div
              :style="stationSelectStyle"
              class="weather-select-content"
            >
              <div
                v-for="(weather, index) in stations"
                :key="`weather-${index}`"
                :class="{
                  weatherboard: true,
                  glassPane: stationStatus[index].temp == null,
                  noConnect: stationStatus[index].temp == null
                }"
              >
                <split-pane
                  :default-percent="40"
                  :min-percent="20"
                  :max-percent="20"
                  resizable="false"
                  split="vertical"
                >
                  <template slot="paneL">
                    <div class="weather-name">
                      <span class="name"> {{ weather.name }}</span>
                    </div>
                    <div class="temperature">
                      <span class="tl"> {{ getTransTmp(stationStatus[index].temp) | roundNumber(0, "--") }}</span>
                      <span class="ts"> {{ tmpUnitHint }}</span>
                    </div>
                  </template>
                  <template slot="paneR">
                    <div class="padding layer1">
                      <div style="display: inline-grid">
                        <span class="label">{{
                          $t("dashboard.wind")
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span class="margintop">{{ getTransSpeed(stationStatus[index].wind) | roundNumber(1, "--") }} <span class="wind">{{ speedUnitHint }}</span></span>
                        <span class="margintop wind">{{ $t("dashboard.from") }} {{ stationStatus[index].directionText }}</span>
                      </div>
                      <img
                        class="select"
                        src="/asserts/icon/wind-direction-bg.svg"
                      >
                      <img
                        v-if="stationStatus[index].direction != null"
                        :style="stationStatus[index].directionRuleStyle"
                        class="select1"
                        src="/asserts/icon/wind-direction-pointer-icon.svg"
                      >
                    </div>
                    <div class="left layer2">
                      <div class="divL">
                        <div style="display: inline-grid;">
                          <span class="label">{{ $t("dashboard.humidity") }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <span>{{ stationStatus[index].humidity | roundNumber(0, "--") }}<span class="wind">%</span></span>
                        </div>
                      </div>
                      <div class="divR">
                        <div style="display: inline-grid;">
                          <span class="label">{{ $t("dashboard.Precipitation") }}</span>
                          <span>{{ stationStatus[index].mmhr | roundNumber(0, "--") }} <span class="wind">mm/hr</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="left layer3">
                      <div class="divL">
                        <div style="display: inline-grid;">
                          <span class="label">{{ $t("dashboard.Pressure") }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <span>{{ stationStatus[index].hpa | roundNumber(0, "--") }}<span class="wind">hpa</span></span>
                        </div>
                      </div>
                    </div>
                  </template>
                </split-pane>
                <div
                  v-if="!stationStatus[index].alive"
                  class="nonoConnection"
                >
                  - {{ $t("dashboard.no_connection") }} -
                </div>
              </div>
            </div>
          </div>
          <button
            :disabled="stationPage >= stationMaxPage"
            class="weather-select__navi image-button next"
            @click="stationPage += 1"
          />
        </template>
      </div>
    </div>
    <div class="flight-summary">
      <div class="flight-container">
        <div class="flight-header">
          <div class="flight-header__title">
            <span class="title">{{ $t("dashboard.flight_summary") }}</span>
          </div>

          <div class="flight-header__select">
            <el-select
              v-model="flightSummariesSelectDrone"
              size="mini"
            >
              <el-option
                v-for="drone in drones"
                :key="drone.id"
                :label="drone.name"
                :value="drone.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="flight-chart-container">
        <flight-summary-chart
          v-if="flightSummaries.length"
          ref="flightSummaryChart"
          :summaries="flightSummaries"
        />
        <div
          v-else
          class="no-device"
        >
          <div class="no-mission__content">
            {{ $t("dashboard.no_data") }}
          </div>
        </div>
      </div>
    </div>

    <div class="drone-nest-summary">
      <div class="drone-nest-summary-header">
        <span>{{ $t("dashboard.drone_nest_status") }}</span>
      </div>
      <div
        v-if="drones.length || nests.length"
        class="drone-item-container"
      >
        <template v-for="(drone, index) in drones">
          <with-root
            :key="`with-root-${index}`"
            :show="false"
          >
            <a href="#">
              <div class="drone-item">
                <div class="drone-item-header">
                  <img src="/asserts/icon/menu-devices-icon.svg">
                  <span>{{ drone.name }}</span>
                </div>

                <div class="drone-item-body">
                  <div class="drone-status-light v2">
                    <div :class="`light ${droneStatus[index].detail}`" />
                    <span class="drone-status">{{ $t(`page.monitor.status.${droneStatus[index].detail}`) }}</span>
                    <!-- <div :class="`light ${droneStatus.timeout.alive ? 'standby' : 'offline'}`"></div>
                <span class="drone-status">{{droneStatus.timeout.alive ? 'standby':'offline'}}</span> -->
                  </div>

                  <div class="battery">
                    <div class="battery-capacity">
                      <span>{{ $t("dashboard.Capacity") }}</span>
                      <img src="/asserts/icon/dronelist-battery-icon.svg">
                      <!-- <span v-if="droneStatus[index].battery_status.remaining != null">{{ droneStatus[index].battery_status.remaining }}%</span> -->
                      <label v-if="droneStatus[index].timeout.alive">{{ droneStatus[index].battery_status.remaining }}%</label>
                      <label v-else>--</label>
                    </div>
                    <div
                      v-if="!detailDrone[index]"
                      class="arrow-down"
                      @click="hideDetail(index)"
                    />
                    <div
                      v-if="detailDrone[index]"
                      class="arrow-up"
                      @click="showDetail(index)"
                    />
                  </div>

                  <div :class="`battery-detail ${detailDrone[index] ? '' : 'hidden'} `">
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[0]
                      "
                      class="voltage"
                    >
                      <span>&#9312;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[0] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9312;---V</span></div>
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[1]
                      "
                      class="voltage"
                    >
                      <span>&#9313;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[1] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9313;---V</span></div>
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[2]
                      "
                      class="voltage"
                    >
                      <span>&#9314;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[2] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9314;---V</span></div>
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[3]
                      "
                      class="voltage"
                    >
                      <span>&#9315;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[3] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9315;---V</span></div>
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[4]
                      "
                      class="voltage"
                    >
                      <span>&#9316;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[4] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9316;---V</span></div>
                    <div
                      v-if="
                        droneStatus[index].battery_status.cell_voltage &&
                          droneStatus[index].battery_status.cell_voltage[5]
                      "
                      class="voltage"
                    >
                      <span>&#9317;</span><span>{{ (droneStatus[index].battery_status.cell_voltage[5] / 1000) | roundNumber(1) }}V</span>
                    </div>
                    <div
                      v-else
                      class="voltage"
                    ><span>&#9317;---V</span></div>
                  </div>
                  <div class="sensor-status-header">
                    <div class="left">
                      <span>{{ $t("dashboard.Sensors") }}</span>
                    </div>
                    <div class="right">
                      <span>{{ $t("dashboard.Status") }}</span>
                    </div>
                  </div>
                  <div :class="`sensor-status-container ${detailDrone[index] ? '' : 'grow'} `">
                    <div class="sensor-status">
                      <div class="left">
                        <span>{{ $t("dashboard.GPS") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.gps_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Compass") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.compass_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Gyro") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.gyro_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Accelerometer") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.accelerometer_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Barometer") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.barometer_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Gimbal") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.gimbal_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.IR_camera") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.irlock_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Rangefinder") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.rangerfinder_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                      <div class="left">
                        <span>{{ $t("dashboard.Battery") }}</span>
                      </div>
                      <div
                        v-if="!droneStatus[index].timeout.alive"
                        class="offline"
                      >
                        <span />
                      </div>
                      <div
                        v-else-if="droneStatus[index].system_status.battery_error == false"
                        class="right"
                      >
                        <span />
                      </div>
                      <div
                        v-else
                        class="error"
                      >
                        <span />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </with-root>
        </template>

        <template v-for="(nest, index) in nests">
          <with-root
            :key="`with-root-${index}`"
            :show="false"
          >
            <a href="#">
              <div
                v-if="(index + 1) % 2 == 1"
                class="drone-item"
              >
                <div class="nest-item-header">
                  <img src="/asserts/icon/dashboard-nest-icon.svg">
                  <span>{{ nest.name }}</span>
                </div>

                <div class="nest-item-body">
                  <div class="nest-item-body-block">
                    <div class="drone-status-light v2">
                      <div :class="`light ${nestStatus[index].detail}`" />
                      <span class="drone-status">{{ $t(`page.monitor.status.${nestStatus[index].detail}`) }}</span>
                      <!-- <div :class="`light ${droneStatus[index].timeout.alive ? 'standby' : 'offline'}`"></div>
                  <span class="drone-status">{{droneStatus[index].timeout.alive ? 'standby' : 'offline'}}</span> -->
                    </div>

                    <div class="border">
                      <div class="border-header">
                        <div class="envLabel">
                          {{ $t("dashboard.env_temp") }}
                        </div>
                        <div class="envLabel2">
                          {{ $t("dashboard.nest_temp") }}
                        </div>
                        <div
                          class="envLabel3"
                          @click="advNestSetting(nest.id)"
                        >
                          <!-- <img class="setting" src="/asserts/icon/dashboard-wpt-edit-icon-normal.svg"/> -->
                        </div>
                      </div>
                      <div class="border-header">
                        <div class="mqEnv">
                          {{ getTransTmp(nestStatus[index].temperature_ambient) | roundNumber(1) }}
                        </div>
                        <div class="degreeC-mqEnv">
                          {{ tmpUnitHint }}
                        </div>
                        <div class="mqEnv2">
                          {{ getTransTmp(nestStatus[index].temperature_internal) | roundNumber(1) }}
                        </div>
                        <div class="degreeC-mqEnv2">
                          {{ tmpUnitHint }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="nests[index + 1]"
                    class="nest-item-header"
                  >
                    <img src="/asserts/icon/dashboard-nest-icon.svg">
                    <span>{{ nests[index + 1].name }}</span>
                  </div>

                  <div
                    v-if="nests[index + 1]"
                    class="nest-item-body-block"
                  >
                    <div class="drone-status-light v2">
                      <div :class="`light ${nestStatus[index + 1].detail}`" />
                      <span class="drone-status">{{ $t(`page.monitor.status.${nestStatus[index + 1].detail}`) }}</span>
                      <!-- <div :class="`light ${drone.liveStatus.timeout.offline==true ? 'offline':'standby'}`"></div>
                  <span class="drone-status">{{drone.liveStatus.timeout.offline==true ? 'offline':'standby'}}</span> -->
                    </div>

                    <div class="border">
                      <div class="border-header">
                        <div class="envLabel">
                          {{ $t("dashboard.env_temp") }}
                        </div>
                        <div class="envLabel2">
                          {{ $t("dashboard.nest_temp") }}
                        </div>
                        <div
                          class="envLabel3"
                          @click="advNestSetting(nests[index + 1].id)"
                        >
                          <!-- <img src="/asserts/icon/dashboard-wpt-edit-icon-normal.svg"/> -->
                        </div>
                      </div>
                      <div class="border-header">
                        <div class="mqEnv">
                          {{ getTransTmp(nestStatus[index+1].temperature_ambient) | roundNumber(1) }}
                        </div>
                        <div class="degreeC-mqEnv">
                          {{ tmpUnitHint }}
                        </div>
                        <div class="mqEnv2">
                          {{ getTransTmp(nestStatus[index+1].temperature_internal) | roundNumber(1) }}
                        </div>
                        <div class="degreeC-mqEnv2">
                          {{ tmpUnitHint }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </with-root>
        </template>
      </div>
      <div
        v-else
        class="no-device"
      >
        <div class="no-mission__content">
          {{ $t("dashboard.no_devices") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import splitPane from 'vue-splitpane';
import { TmpMeasure, SpeedMeasure } from '@/utils/measure';

import weatherApi from '@/services/api/domain/group/weather.js';
import histroyApi from '@/services/api/domain/group/histroy';
import DroneApi from '@/services/api/domain/group/drone';
import NestApi from '@/services/api/domain/group/nest';
import FlightSummaryChart from '@/components/FlightSummaryChart';
import WithRoot from '@/components/WithRoot.vue';
import { WindDirection } from '@/utils/winddirections.js';

export default {
  name: 'Dashboard',
  components: {
    splitPane,
    FlightSummaryChart,
    WithRoot,
  },
  data() {
    return {
      // .drone style: width 200px + margin-right 19px
      detailDrone: [],
      drones: [],
      flightSummariesSelectDrone: null,
      flightSummaries: [],
      nests: [],
      stations: [],
      stationPage: 0,
      stationSingleWidth: 338 + 16,
      timer: null,
      loadingWeatherData: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: 'user/user',
      groups: 'user/groups',
      group: 'user/group',
      groupId: 'user/groupId',
      droneLatestMessage: 'mqtt/droneLatestMessage',
      droneLatestStatus: 'mqtt/droneStatus',
      nestLatestMessage: 'mqtt/nestLatestMessage',
      weatherLatesetMessage: 'mqtt/weatherLatesetMessage',
    }),
    stationPerPage() {
      return 1;
    },
    stationMaxPage() {
      return this.stations.length - 1;
    },
    stationSelectStyle() {
      return {
        'margin-left': `${this.stationPage * this.stationPerPage * this.stationSingleWidth * -1}px`,
      };
    },
    droneIds() {
      return this.drones.map((drone) => drone.id);
    },
    nestIds() {
      return this.nests.map((nest) => nest.id);
    },
    droneStatus() {
      return this.drones.map((drone) => {
        const status = this.droneLatestStatus[drone.id];
        const message = this.droneLatestMessage[drone.id];

        status.heartbeat = message.heartbeat || {};
        status.timeout = message.timeout || {};
        status.battery_status = message.battery_status || {};
        status.system_status = message.system_status || {};

        return status;
      });
    },
    nestStatus() {
      return this.nests.map((nest) => {
        const nestMessage = this.nestLatestMessage[nest.id];
        const status = { detail: 'offline' };
        if (nestMessage.heartbeat == null || nestMessage.timeout == null || !nestMessage.timeout.alive) {
          return status;
        }

        return {
          detail: nestMessage.heartbeat.cover_status,
          ...nestMessage.heartbeat,
        };
      });
    },
    stationStatus() {
      return this.stations.map((station) => {
        const stationMessage = this.weatherLatesetMessage[station.id];
        const status = { alive: false };

        if (stationMessage.timeout != null) {
          status.alive = stationMessage.timeout.alive;
        }

        if (stationMessage.heartbeat != null) {
          status.hpa = stationMessage.heartbeat.pressure;
          status.temp = stationMessage.heartbeat.temperature_outside;
          status.mmhr = stationMessage.heartbeat.accumulated_precipitation;
          status.wind = stationMessage.heartbeat.wind_speed;
          status.humidity = stationMessage.heartbeat.humidity_outside;
          const direction = stationMessage.heartbeat.wind_direction;
          status.direction = direction;
          const directionLabel = this.getDirectionLabel(direction);
          status.directionText = this.$t(`dashboard.${directionLabel}`);
          status.directionRuleStyle = this.getDirectionRuleStyle(direction);
        }

        return status;
      });
    },
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    tmpUnitHint() {
      return TmpMeasure.unit();
    },
  },
  watch: {
    flightSummariesSelectDrone(now, old) {
      if (now !== old) {
        this.loadDroneHistroy();
      }
    },
  },
  created() {
    if (!this.groupId) {
      this.$router.push({ name: 'selectGroup' });
    }
  },
  async destroyed() {
    this.stations.forEach((station) => {
      this.unsubscribeWeather({ weather: station });
    });
    this.drones.forEach((drone) => {
      this.unsubscribeDrone({ drone });
    });
    this.nests.forEach((nest) => {
      this.unsubscribeNest({ nest });
    });
    clearInterval(this.timer);
  },
  async mounted() {
    if (!this.group || !this.group.id) {
      return;
    }
    weatherApi
      .getAll(this.group.id)
      .then((response) => {
        const { stations } = response.data;
        this.stations = stations.filter((item) => item.inactive === false);
        this.stations.forEach((station) => {
          this.subscribeWeather({ weather: station });
        });
        this.updateWeatherStatus();
        this.timer = setInterval(this.updateWeatherStatus, 60 * 1000);
        // this.simulateWeather();
      })
      .catch(this.$showFail);
    NestApi.getAll(this.group.id)
      .then((response) => {
        const nests = response.data.nests.filter((nest) => !nest.inactive);
        this.nests = nests;
        this.nests.forEach((nest) => {
          this.subscribeNest({ nest });
        });
      })
      .catch(this.$showFail);
    DroneApi.getAll(this.group.id)
      .then((response) => {
        const drones = response.data.drones.filter((drone) => !drone.inactive);
        this.drones = drones;
        this.drones.forEach((drone) => {
          this.subscribeDrone({ drone });
        });
        if (this.drones.length) {
          this.flightSummariesSelectDrone = this.drones[0].id;
        }
      })
      .catch(this.$showFail);
  },
  methods: {
    ...mapActions({
      subscribeDrone: 'mqtt/subscribeDrone',
      unsubscribeDrone: 'mqtt/unsubscribeDrone',
      subscribeNest: 'mqtt/subscribeNest',
      unsubscribeNest: 'mqtt/unsubscribeNest',
      subscribeWeather: 'mqtt/subscribeWeather',
      unsubscribeWeather: 'mqtt/unsubscribeWeather',
      setWeatherStatus: 'mqtt/setWeatherStatus',
    }),

    advNestSetting(nestId) {
      const queryParams = {
        nestId,
      };
      this.$router.push({
        name: 'device',
        // params: {
        //   title: '123',
        // },
        query: queryParams,
      });
    },
    async loadDroneHistroy() {
      if (this.$refs.flightSummaryChart) {
        this.$refs.flightSummaryChart.loading();
      }
      const histroySummary = await histroyApi.histroySummery(this.group.id, this.flightSummariesSelectDrone);
      this.flightSummaries = histroySummary.data.summaries;
    },
    async updateWeatherStatus() {
      if (this.loadingWeatherData) {
        return;
      }
      this.loadingWeatherData = true;
      for (let i = 0; i < this.stations.length; i += 1) {
        const station = this.stations[i];
        if (station.station_id != '') {
          try {
            const data = await weatherApi.status(this.group.id, station.station_id);
            this.setWeatherStatus({
              weather: station,
              payload: data,
            });
          } catch (error) {
          }
        }
      }
      this.loadingWeatherData = false;
    },
    showDetail(index) {
      this.$set(this.detailDrone, index, false);
    },
    hideDetail(index) {
      this.$set(this.detailDrone, index, true);
    },
    toDegrees(angle) {
      return angle * (180 / Math.PI);
    },
    toRadians(angle) {
      return angle * (Math.PI / 180);
    },
    getDirectionLabel(direction) {
      return WindDirection(direction);
    },
    getDirectionRuleStyle(direction) {
      if (direction == null) {
        return { transform: '' };
      }
      const radius = 31;
      const x = radius * Math.cos(this.toRadians(90 - direction));
      const radiusY = 70;
      let y = 0;

      if (direction <= 180) {
        y = radiusY * (direction / 180);
      } else {
        y = radiusY - radiusY * ((direction - 180) / 180);
      }

      return {
        'margin-top': `${y}px`,
        'margin-left': `${-35 + x}px`,
        transform: `rotate(${180 + direction}deg)`,
      };
    },
    simulateWeather() {

    },
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0);
    },
    getTransTmp(value, digit = 1) {
      return TmpMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  background-color: $light-gray-50;
  height: calc(100vh - 56px);

  overflow-x: hidden;
  overflow-y: auto;

  .flight-summary {
    @include dashboard-section-style;
    @include tablet-extra-large-1013px {
      width: 85vw;
      margin: 19px auto 0;
      float: none;
    }
    width: 47%;
    overflow: hidden;
    height: 260px;
    margin: 19px 0 0 19px;
  }

  .drone-nest-summary {
    @include dashboard-section-style;
    @include tablet-extra-large-1013px {
      width: 85vw;
      margin: 19px auto 19px;
      float: none;
    }
    width: calc(94% + 19px);
    margin: 19px calc((100% - (94% + 10px)) / 2);

    .drone-nest-summary-header {
      width: 100%;
      height: 37.5px;
      position: relative;
      /* border-width: 1px; */
      /* border-color: #979797; */
      /* border-style: solid; */
      border-bottom: 1px solid rgba(178, 178, 178, 0.3);

      > span {
        width: 200px;
        height: 18px;
        font-size: 16px;
        @include normal-font-style;
        color: $dark-gray-500;
        margin-top: 9px;
        margin-left: 30px;
        position: absolute;
      }
    }

    .drone-item-container {
      overflow-x: hidden;
      overflow-y: visible;
      padding: 16px 16px;
      min-height: 300px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      @include tablet-medium-min-800px {
        grid-template-columns: repeat(3, 1fr);
      }
      @include tablet-extra-large-min-1013px {
        grid-template-columns: repeat(4, 1fr);
      }
      @include desktop-small-min-1200px {
        grid-template-columns: repeat(5, 1fr);
      }
      @include desktop-normal-min-1438px {
        grid-template-columns: repeat(6, 1fr);
      }
      @include desktop-medium-min-1678px {
        grid-template-columns: repeat(7, 1fr);
      }
      @include desktop-large-min-1917px {
        grid-template-columns: repeat(8, 1fr);
      }
      @include desktop-extra-large-min-2157px {
        grid-template-columns: repeat(9, 1fr);
      }
    }

    .drone-item {
      height: 326px;
      border-radius: 4px;
      position: relative;

      .border {
        height: 71px;
        border: 1px solid $light-gray-300;

        .border-header {
          display: -webkit-inline-box;
          margin-top: 8px;
          width: 100%;
          display: flex;
          justify-content: space-around;
          position: relative;
        }
      }

      .nest-item-header {
        @include dashboard-item-header;
        @include tablet-extra-large-1013px {
          width: 100vw;
        }
        background-color: $light-yellow-200;
        > img {
          margin-left: 7px;
          margin-right: 5px;
          width: 32px;
          margin-bottom: 2px;
        }
        > span {
          @include dashboard-header-span;
          @include normal-font-style-500;
        }
      }

      .drone-item-header {
        @include dashboard-item-header;
        @include tablet-extra-large-1013px {
          width: 100vw;
        }
        background-color: $light-blue-200;
        > img {
          margin-left: 7px;
          margin-right: 5px;
          width: 22px;
        }
        > span {
          @include dashboard-header-span;
          @include normal-font-style-500;
        }
      }

      .nest-item-body {
        height: 291px;
        @include tablet-extra-large-1013px {
          width: 100%;
        }

        .v2 {
          margin-left: 0px;
          height: 22%;
          display: flex;
          align-items: center;
          padding: 0 0.15rem;
          line-height: initial;
        }

        .nest-item-body-block {
          background-color: rgba(#d8d8d8, 0.31);
          height: 120px;
          padding: 0px 10px;
          margin-bottom: 16px;
        }

        .envLabel2, .envLabel {
            @include normal-font-style;
            flex: 1;
            height: 14px;
            margin-left: 12px;
            font-size: 12px;
            color: $dark-gray-100;
        }

        .envLabel3 {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background-image: url("/asserts/icon/dashboard-wpt-edit-icon-normal.svg");
        }

        .envLabel3:hover {
          cursor: pointer;
          background-image: url("/asserts/icon/missions-wpt-edit-icon-normal.svg");
        }

        .mqEnv,
        .mqEnv2 {
          @include normal-font-style;
          font-size: 23px;
          color: $dark-gray-100;
          flex: 1;
          margin-left: 4px;
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .degreeC-mqEnv,
        .degreeC-mqEnv2 {
          @include normal-font-style;
          font-size: 13px;
          color: $dark-gray-100;
          margin-top: 10px;
          margin-right: 8px;
        }
        .degreeC-mqEnv2 {
          margin-right: 24px;
        }
      }

      .drone-item-body {
        width: 100%;
        height: 291px;
        background-color: rgba(#d8d8d8, 0.31);
        padding: 0px 10px;
        @include tablet-extra-large-1013px {
          width: 100%;
        }

        .v2 {
          margin-left: 0px;
          height: 8.5%;
          display: flex;
          align-items: center;
          line-height: initial;
          padding: 0 0.15rem;
        }
        .battery {
          height: 24px;
          background-color: $light-gray-100;
          display: -webkit-box;
          position: relative;
          .battery-capacity {
            position: absolute;
            left: 4%;
            top: 15%;
            img {
              margin-right: 0.3rem;
            }
            span {
              width: 16px;
              font-size: 14px;
              margin-right: 0.3rem;
            }
            label {
              display: -webkit-inline-box;
            }
          }
          .arrow-up {
            @include arrow-style;
            border-bottom: 5px solid #2f2f2f;
            top: 35%;
          }

          .arrow-down {
            @include arrow-style;
            border-top: 5px solid #2f2f2f;
            top: 35%;
          }
        }

        .battery-detail {
          background-color: $light-gray-200;
          height: 16%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .voltage {
            width: 33%;
            display: flex;
            justify-content: center;
            span {
              font-size: 12px;
              color: $dark-gray-100;
              margin-left: 1.5px;
            }
          }

          .voltage:first-child,
          .voltage:nth-child(4) {
            justify-content: flex-start;
            padding-left: 8px;
          }
          .voltage:nth-child(3),
          .voltage:nth-child(6) {
            justify-content: flex-start;
            padding-left: 15px;
            @include tablet-medium-820px {
              padding-left: 37px;
            }
          }

          .numberCircle {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            padding: 6px;
            background: $light-gray-200;
            border: 1px solid $dark-gray-100;
            color: $dark-gray-100;
            text-align: center;
            font: 6px Arial, sans-serif, bold;
          }
        }

        .hidden {
          display: none;
        }

        .sensor-status-header {
          height: 27px;
          margin-top: 10px;
          position: relative;
          font-size: 12px;
          color: $dark-gray-100;
          .left {
            float: left;
            width: 100%;
            border-bottom: 1px solid $normal-black;
          }
          .right {
            overflow: hidden;
            border-bottom: 1px solid $normal-black;
            position: absolute;
            right: 0;
          }
        }
        .sensor-status-container {
          overflow-y: auto;
          height: 157px;
          font-size: 12px;
          color: $dark-gray-500;
          .sensor-status {
            .left {
              float: left;
              width: 88%;
              @include tablet-medium-820px {
                width: 91%;
              }
            }
            .right,
            .error,
            .offline {
              @include dashboard-sensor-status;
            }
            .right {
              background-image: url("/asserts/icon/drone-status-ready-icon.svg");
            }
            .error {
              background-image: url("/asserts/icon/drone-status-not-ready-icon.svg");
            }
            .offline {
              background-image: url("/asserts/icon/drone-status-offline-icon.svg");
            }
          }
        }

        .grow {
          height: 204px;
        }
      }
    }

    .no-device {
      @include no-device;
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      .no-mission__content {
        font-size: 24px;
        @include normal-font-style-500;
        color: $light-gray-400;
      }
    }
  }
}

.weather-container {
  @include dashboard-section-style;
  @include tablet-extra-large-1013px {
    width: 85vw;
    margin: 19px auto 0;
    float: none;
  }
  width: 47%;
  overflow: hidden;
  margin: 19px 0 0 calc((100% - (94% + 12px)) / 2);
  height: 260px;
}

.flight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  @include dashboard-weather-flight-header;
  span.title {
    font-size: 16px;
    @include normal-font-style;
    color: $dark-gray-500;
  }
}

.flight-chart-container {
  height: 222.5px;
  position: relative;

  .no-device {
    @include no-device;

    .no-mission__content {
      @include no-mission__content;
    }
  }
}

.weather-header {
  @include dashboard-weather-flight-header;
  > span {
    @include dashboard-weather-flight-span;
    margin: 9px 0px 0px 30px;
  }
}
.weatherboard {
  position: relative;
  top: 14.5px;
  left: 1px;
  width: 338px;
  height: 190px;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px rgba(178, 178, 178, 0.8);
  background-color: $normal-white;
  border-width: 1px;
  margin-right: 16px;

  .padding.layer1 {
    position: relative;
    height: 90px;
    left: 20px;
    top: 12px;
    display: flex;
    .divL {
      display: inline;
    }
    .divR {
      display: inline;
      width: 50%;
    }
    .select {
      margin-top: 8px;
      margin-left: 20px;
      width: 62px;
      height: 62px;
    }
    .select1 {
        height: 10px;
        margin-top: -12px;
        margin-left: -35px;
        transform: rotate(180deg);
      }
      .wind {
        // font-family: HelveticaNeue;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #242424;
        // margin-top: -18px;
      }
      .margintop {
        margin-top: -18px;
      }
  }

  .left.layer2 {
    position: relative;
    left: 20px;
    .divL {
      display: inline;
    }
    .divR {
      display: inline;
      width: 50%;
    }
    .wind {
      font-size: 14px;
      @include normal-font-style;
      color: $dark-gray-500;
      margin-top: -18px;
    }
    .margintop {
      margin-top: -18px;
    }
  }

  .left.layer3 {
    position: relative;
    left: 20px;
  }
  .divL {
    display: inline;
  }
  .divR {
    display: inline;
    width: 50%;
  }
  .wind {
    font-size: 14px;
    @include normal-font-style;
    color: $dark-gray-500;
  }
  .margintop {
    margin-top: -18px;
  }
}

.splitter-pane-resizer.vertical {
  width: 11px !important;
  height: 88% !important;
  top: 15px;
}

.temperature {
  position: relative;
  top: 24px;

  span.tl {
    width: 67px;
    height: 70px;
    padding-left: 12px;
    font-size: 60px;
    @include normal-font-style;
    text-align: center;
    color: $light-blue-200;
  }

  span.ts {
    width: 67px;
    height: 70px;
    font-size: 32px;
    @include normal-font-style;
    text-align: center;
    color: $light-blue-200;
  }
}

span.label {
  height: 16px;
  font-size: 14px;
  @include normal-font-style;
  color: $dark-gray-100;
}

.weather-select__navi {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.weather-select__navi.pervious {
  background-image: url("/asserts/icon/dronelist-icon-previous.svg");
}

.weather-select__navi.next {
  right: 0px;
  background-image: url("/asserts/icon/dronelist-icon-back-next.svg");
  background-color: $normal-white;
  z-index: 2;
}

.weather-select__navi.next::before {
  height: 76px;
  top: -76px;
}

.weather-select__navi.next::after {
  height: 100px;
  bottom: -100px;
}

.weather-select__navi.next::before,
.weather-select__navi.next::after {
  content: "";
  background: $normal-white;
  position: absolute;
  left: 0;
  width: 32px;
}

.weather-select__navi.pervious,
.weather-select__navi.next {
  width: 30px;
  height: 30px;
}

.weather-select-container {
  width: 100%;
  height: 222.5px;
  display: -webkit-inline-box;
  @include tablet-extra-large-1013px {
    width: 80vw;
  }

  .no-device {
    @include no-device;

    .no-mission__content {
      @include no-mission__content;
    }
  }

  .glassPane {
    background-color: rgba(#ececec, 0.4);
  }

  .nonoConnection {
    position: relative;
    background-color: $light-gray-150;
    height: 40px;
    width: 100%;
    bottom: 41px;
    text-align: center;
    padding-top: 10px;
  }
}

.weather-select-content {
  display: -webkit-inline-box;
  overflow: hidden;
  height: 222.5px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.weather-select-anchor-pane {
  position: relative;
  left: 30px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  @include tablet-extra-large-1013px {
    margin: 0 auto;
    width: 90%;
    left: 3.5%;
  }
}

.weather-name {
  width: 123px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.49);
  position: relative;
  top: 12px;

  > span {
    font-size: 12px;
    left: 6px;
    @include normal-font-style;
    color: $normal-white;
    margin-left: 4px;
  }
}
</style>
