/* eslint-disable max-len */
import state from '@/store/state.js';

function toFixed(result, digit = 0, round = 0, mode = 'ceil') {
  if (result == null) {
    return null;
  }
  const around = Math.max(round, 1);
  const pow = 10 ** Math.max(digit, 0);
  const mulpyFix = pow / around;

  switch (mode) {
    case 'ceil':
      return Math.ceil(result * mulpyFix) / mulpyFix;
    case 'floor':
      return Math.floor(result * mulpyFix) / mulpyFix;
    default:
      return Math.round(result * mulpyFix) / mulpyFix;
  }
}

function trans(value, scale = 1, preOffset = 0, postOffset = 0, digit = 0, round = 0, mode = 'ceil') {
  if (value == null) {
    return null;
  }
  const result = ((value + preOffset) * scale + postOffset);
  const fixed = toFixed(result, digit, round, mode);
  return fixed;
}

function meterToFootTrans(value, digit, round, mode) {
  return trans(value, 3.2808, 0, 0, digit, round, mode);
}

function footToMeterTrans(value, digit, round, mode) {
  return trans(value, 0.3048, 0, 0, digit, round, mode);
}

function meter2ToFootTrans2(value, digit, round, mode) {
  return trans(value, 10.7636, 0, 0, digit, round, mode);
}

function foot2ToMeterTrans2(value, digit, round, mode) {
  return trans(value, 0.929, 0, 0, digit, round, mode);
}

function cmToInceTrans(value, digit, round, mode) {
  return trans(value, 0.3937, 0, 0, digit, round, mode);
}

function inchToCmTrans(value, digit, round, mode) {
  return trans(value, 2.54, 0, 0, digit, round, mode);
}

function msToMphTrans(value, digit, round, mode) {
  return trans(value, 2.2370, 0, 0, digit, round, mode);
}

function mphToMsTrans(value, digit, round, mode) {
  return trans(value, 0.4470, 0, 0, digit, round, mode);
}

function celsiusToFahrenheitTrans(value, digit, round, mode) {
  return trans(value, 1.8, 0, 32.0, digit, round, mode);
}

function fahrenheitToCelsiusTrans(value, digit, round, mode) {
  return trans(value, 1 / 1.8, -32.0, 0, digit, round, mode);
}

function defaultTrans(value, digit, round, mode) {
  return toFixed(value, digit, round, mode);
}

const measurements = {
  imperial: {
    distance: {
      displayTrans: meterToFootTrans,
      realTrans: footToMeterTrans,
      unit: 'ft',
    },
    area: {
      displayTrans: meter2ToFootTrans2,
      realTrans: foot2ToMeterTrans2,
      unit: 'ft',
    },
    mDistance: {
      displayTrans: cmToInceTrans,
      realTrans: inchToCmTrans,
      unit: 'in',
    },
    speed: {
      displayTrans: msToMphTrans,
      realTrans: mphToMsTrans,
      unit: 'mph',
    },
    temperautre: {
      displayTrans: celsiusToFahrenheitTrans,
      realTrans: fahrenheitToCelsiusTrans,
      unit: '°F',
    },
  },
  metric: {
    distance: {
      displayTrans: defaultTrans,
      realTrans: defaultTrans,
      unit: 'm',
    },
    area: {
      displayTrans: defaultTrans,
      realTrans: defaultTrans,
      unit: 'm',
    },
    mDistance: {
      displayTrans: defaultTrans,
      realTrans: defaultTrans,
      unit: 'cm',
    },
    speed: {
      displayTrans: defaultTrans,
      realTrans: defaultTrans,
      unit: 'm/s',
    },
    temperautre: {
      displayTrans: defaultTrans,
      realTrans: defaultTrans,
      unit: '°C',
    },
  },
};

function usingMeasurement() {
  return measurements[state.measurement] ? measurements[state.measurement] : measurements.metric;
}

export const DisMeasure = {
  display: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().distance.displayTrans(meter, digit, round, mode),
  real: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().distance.realTrans(meter, digit, round, mode),
  unit: () => usingMeasurement().distance.unit,
};

export const MDisMeasure = {
  display: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().mDistance.displayTrans(meter, digit, round, mode),
  real: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().mDistance.realTrans(meter, digit, round, mode),
  unit: () => usingMeasurement().mDistance.unit,
};

export const AreaMeasure = {
  display: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().area.displayTrans(meter, digit, round, mode),
  real: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().area.realTrans(meter, digit, round, mode),
  unit: () => usingMeasurement().area.unit,
};

export const TmpMeasure = {
  display: (celsius, digit = 0, round = 0, mode = 'round') => usingMeasurement().temperautre.displayTrans(celsius, digit, round, mode),
  real: (celsius, digit = 0, round = 0, mode = 'round') => usingMeasurement().temperautre.realTrans(celsius, digit, round, mode),
  unit: () => usingMeasurement().temperautre.unit,
};

export const SpeedMeasure = {
  display: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().speed.displayTrans(meter, digit, round, mode),
  real: (meter, digit = 0, round = 0, mode = 'round') => usingMeasurement().speed.realTrans(meter, digit, round, mode),
  unit: () => usingMeasurement().speed.unit,
};
